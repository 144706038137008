import { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Typography, Container, Grid, Paper, Box, Radio, TextField, Button, CircularProgress, Snackbar } from '@material-ui/core';
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import api from '../utils/api';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
          color: '#757575'
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: '#757575',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {},
        '&:hover fieldset': {},
        '&.Mui-focused fieldset': {
          borderColor: '#757575',
        },
      },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    button: {
        textTransform: 'none',
        backgroundColor: 'rgb(6, 62, 249)',
        boxShadow: 'none',
        fontFamily: 'RobotoLight',
        borderRadius: '8px'
    },
    img: {
        width: '110px',
        paddingBottom: '20px'
    },
    container: {
        width: '40%',
        '@media (max-width: 1024px)': {
            width: '100%'
        }
    }
}));

function Login() {

    const classes = useStyles();

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [isLoading, setIsLoading] = useState()

    const handleLogin = () => {

        setIsLoading(true)

        api.post('auth/login', {
            email: email,
            password: password
        }, {})
        .then(res => {
            setIsLoading(false)
            if(window.location.hostname == 'localhost'){
                window.location.href = 'http://localhost:3001?token=' + res.data.token;
            }else{
                window.location.href = 'https://backoffice.appandwebstudio.com.br?token=' + res.data.token;
            }
        })
        .catch(err => {
            setIsLoading(false)
            setOpenSnackbar('true')
            setMessageSnackbar('Dados inválidos!')
            setSeveritySnackbar('error')
        })

    }

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [severitySnackbar, setSeveritySnackbar] = useState('success');

    const handleOnKeyPress = (e) => {

        if(e.key === 'Enter'){
            if(email && password){
                handleLogin()
            }
        }

    }

    return (
        <div className={classes.root}>

            <Container className={classes.container}>
                {/*<Link to="/">
                    <div style={{ marginBottom: '20px' }}>
                        <KeyboardBackspaceRoundedIcon />
                    </div>
                </Link>*/}
                <Link to="/">
                    <img alt="App&Web" src={logo} className={classes.img} />
                </Link>
                <Typography variant="h6" style={{fontFamily: 'RobotoLight', paddingBottom: '30px'}}>Acesse sua conta</Typography>
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <CssTextField onKeyPress={handleOnKeyPress} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setEmail(e.target.value)} value={email} id="outlined-basic" label="E-mail" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <CssTextField onKeyPress={handleOnKeyPress} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setPassword(e.target.value)} value={password} id="outlined-basic" label="Senha" variant="outlined" fullWidth type="password" />
                    </Grid>
                    <Grid item md={12} xs={12} style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <Button onClick={handleLogin} className={classes.button} disabled={!email || !password || isLoading ? true : false} variant="contained" color="primary" size="large" fullWidth>
                            {isLoading ?
                                <CircularProgress color="#FFF" size={26} />
                            :
                                'Entrar'
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

        </div>
    );
}

export default Login;