import React, { useState, createContext, useContext } from "react";
import { translated_messages as translatedMessages } from '../translated_messages';

export const GlobalContext = createContext({})

export function GlobalProvider({children}){

    const [ lang, setLang ] = useState(localStorage.getItem('appandweb_language') || 'pt_BR')

    const translated_messages = translatedMessages[lang];

    return(  
        <GlobalContext.Provider 
            value={{
                translated_messages,
                lang,
                setLang
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobal = () => useContext(GlobalContext);