import React, { useState, useEffect, useRef } from 'react';
import { TextField, MenuItem, Box } from '@material-ui/core';
import { ArrowDropDownRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { countries } from '../utils';
import { useGlobal } from '../context';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent'
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
      '& .MuiSelect-outlined': {
        // paddingRight: '8px',
      },
      '& .MuiSelect-icon': {
        color: '#808080'
      },
      '& .MuiInputBase-root': {
        height: '24px',
        // paddingRight: '8px',
      },
      '& .country-flag': {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginTop: '-2px'
      }
    },
  },
  selectedMenuItem: {
    '&.Mui-selected': {
      backgroundColor: 'rgb(250, 250, 250) !important',
      borderRadius: '8px'
    }     
  },
}));

const CountrySelect = (props) => {

  const classes = useStyles();
  const { lang, setLang } = useGlobal();
  const [ country, setCountry ] = useState('pt_BR');
  const selectRef = useRef(null); 

  useEffect(() => {
    const accept_language = localStorage.getItem('appandweb_language');
    if (accept_language) {
      const new_lang = countries.find((c) => c.accept_language == accept_language)?.accept_language;
      setCountry(new_lang);
    }
  }, []);

  useEffect(() => {
    const c = countries.find((c) => c.accept_language == lang);
    setCountry(c?.accept_language);
  },[lang])

  const handleChange = (event) => {
    setCountry(event.target.value);
    const countrieAux = countries.find((c) => c.accept_language == event.target.value);
    if (props?.refresh) {
      localStorage.setItem('appandweb_language', countrieAux.accept_language);
      setLang(countrieAux.accept_language)    
    }
    selectRef.current.blur();
  };

  return (
    <Box sx={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '8px', padding: '8px 2px 8px 2px', ...props.sx }}>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          size="small"
          id="outlined-select-country"
          select
          value={country}
          onChange={handleChange}
          variant="outlined"
          ref={selectRef}
          // SelectProps={{
          //   IconComponent: () => (
          //     <ArrowDropDownRounded style={{ color: '#808080' }} />
          //   ),
          // }}
        >
          {countries.map((option) => (
            <MenuItem
              key={option.accept_language}
              value={option.accept_language}
              selected={option.accept_language === country}
              className={classes.selectedMenuItem}
            >
              {option.flag}
            </MenuItem>
          ))}
        </TextField>
      </form>
    </Box>
  );
};

export default CountrySelect;
