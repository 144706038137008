import { useState } from "react";
import { Box, Paper, Modal, Fade, Backdrop, Typography, Grid, IconButton, Tooltip, Hidden, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import { CloseRounded, ArrowRightAltRounded, TouchAppOutlined } from "@material-ui/icons";
import { useGlobal } from '../context';

const useStyles = makeStyles((theme) => ({
    card: {
        cursor: 'pointer',
        backgroundColor: '#F8F8F8', 
        padding: '100px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '60px', 
        borderRadius: '8px',
        position: 'relative',
        ['@media (max-width: 779px)']: {
            height: '0px'
        }
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '30px',
        borderRadius: '10px',
        width: '50vw',
        '@media (max-width: 779px)': {
            width: '90vw'
        },
        maxHeight: '80vh',
        overflowY: 'auto',
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        top: 30,
        right: 30,
        color: 'gray',
        fontSize: '24px'
    }
}));

const Item = props => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const { translated_messages } = useGlobal();

    return (
        <>
            <Box>
                {/* <a href={props.data.href} target="_blank"> */}
                    <Paper elevation="0" className={classes.card} onClick={() => {setOpen(!open)}}>
                        <TouchAppOutlined className={classes.icon} />
                        {/* {props.data.short_description && (
                            <Chip style={{ position: 'absolute', bottom: 30, right: 30, cursor: 'pointer', backgroundColor: 'transparent' }} label={props.data.short_description} />
                        )} */}
                        <img 
                            draggable={false} 
                            src={props.data.src} 
                            style={{
                                width: props.data.width
                            }} 
                        />
                    </Paper>
                {/* </a> */}
            </Box>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => {setOpen(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open} style={{ outline: 'none' }}>
                    <div className={classes.paper}>
                        <Tooltip onClick={() => {setOpen(false)}} title="Fechar" style={{ position: 'absolute', top: 20, right: 20 }}>
                            <IconButton>
                                <CloseRounded />
                            </IconButton>
                        </Tooltip>
                        <img 
                            draggable={false} 
                            src={props.data.src} 
                            style={{
                                width: props.data.width,
                                margin: 'auto'
                            }} 
                        />
                        <Typography color="textSecondary" variant="body1" style={{ marginTop: '20px', marginBottom: '20px' }}>{props.data.description}</Typography>
                        {/* <Typography variant="body1">{translated_messages.modal_solution_label_name}: {props.data.name}</Typography>
                        <Typography variant="body1">{translated_messages.modal_solution_label_segment}: {props.data.segment}</Typography> */}
                        {/* <Typography variant="body1">Fundação{props.data.name}</Typography> */}
                        <a href={props.data.href} target="_blank" style={{ textDecoration: 'none', display: 'block', width: 'fit-content' }}>
                            <Typography color="textSecondary" variant="body1" style={{ marginTop: '20px', alignItems: 'center', display: 'flex', width: 'fit-content' }}>{translated_messages.modal_solution_visit_label} <ArrowRightAltRounded style={{ marginLeft: '8px' }} /></Typography>
                        </a>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default Item;