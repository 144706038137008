import axios from 'axios';

const location = window.location;

let url = "http://api.appandwebstudio.com.br/";

if(location == 'http://localhost:3002/' || location == 'http://localhost:3001/' || location == 'http://localhost:3000/'){
    url = 'http://localhost:8000/';
}else{
    url = 'https://api.appandwebstudio.com.br/';
}

export default axios.create({
  baseURL: url
});