import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './pages/home';
import Login from './pages/login';
import Requisition from './pages/clients/requisition';
import reportWebVitals from './reportWebVitals';
import Error from './pages/error';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './fonts/Roboto/Roboto-Regular.ttf';
import { GlobalProvider } from './context';

ReactDOM.render(
  <GlobalProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route path="/login" exact={true} component={Login} />
        <Route path="/projects/requisition" exact={true} component={Requisition} />
        <Route path="/*" exact={true} component={Error} />
      </Switch>
    </ BrowserRouter>
  </GlobalProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
