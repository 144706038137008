import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import logo from '../assets/logo.png'
import { Container, Typography, Toolbar, AppBar, Tooltip, Button, Modal, Fade, Backdrop, Grid, CircularProgress, TextField, IconButton, Hidden, Box, Drawer } from '@material-ui/core'
import { Link } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import api from '../utils/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputMask from 'react-input-mask';
import { CloseOutlined, MenuRounded } from '@material-ui/icons';
import CountrySelect from './country-select';
import { useGlobal } from '../context';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#000'
    },
    title: {
        flexGrow: 1,
        color: '#000'
    },
    appBar: {
        backgroundColor: '#FFF',
        paddingTop: '20px',
        paddingBottom: '20px',
        boxShadow: 'none',
        borderBottom: '1px solid #F2F2F2'
    },
    img: {
        width: '100px',
        paddingLeft: '0px'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#4A77FF',
        color: '#FFF',
        boxShadow: 'none',
        ['@media (max-width: 779px)']: {
            // width: '100%'
        },
        borderRadius: '20px',
        marginRight: '20px',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        borderRadius: '10px',
        width: '50vw',
        outline: 'none',
        '@media (max-width: 779px)': {
            width: '90vw'
        },
        maxHeight: '80vh',
        overflowY: 'auto',
        position: 'relative'
    },
    buttonContact: {
        textTransform: 'none',
        backgroundColor: '#4A77FF',
        color: '#FFF',
        boxShadow: 'none',
        fontFamily: 'RobotoLight',
        borderRadius: '8px',
        paddingLeft: '20px',
        paddingRight: '20px',
        ['@media (max-width: 779px)']: {
            width: '100%'
        }
    },
    link: {
        color: '#000',
        marginLeft: '20px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    links: {
        display: 'flex',
        ['@media (max-width: 779px)']: {
            display: 'none'
        }
    },
    iconMenu: {
        color: '#000',
        marginLeft: '10px',
        ['@media (min-width: 780px)']: {
            display: 'none'
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
          color: '#757575'
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: '#757575',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {},
        '&:hover fieldset': {},
        '&.Mui-focused fieldset': {
          borderColor: '#757575',
        },
      },
    },
})(TextField);

const links = [
    {
        'text': 'Frametask',
        'link': 'https://frametask.appandwebstudio.com.br'
    },
    {
        'text': 'Legin',
        'link': 'https://legin.appandwebstudio.com.br'
    },
    {
        'text': 'Levo',
        'link': 'https://levo.com.br'
    },
    {
        'text': 'Ekvalu',
        'link': 'https://ekvalu.com.br'
    }
]

const Menu = props => {

    const { translated_messages } = useGlobal();
    const classes = useStyles();

    const getHeight = () => {
        const elem = document.getElementById("menu");
        if(props.handleHeight){
            props.handleHeight(elem.clientHeight);
        }
    }

    useEffect(() => {
        getHeight();
    }, [])

    const [openModal, setOpenModal] = useState(false);

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [description, setDescription] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const handleSendContact = () => {

        setIsLoading(true);

        api.post(`site/send-contact`, {
            name: name,
            email: email,
            contact: contact,
            message: description
        })
        .then(res => {
            setName('');
            setContact('');
            setEmail('');
            setDescription('');
            setIsLoading(false);
            setOpenModal(false);
            setOpenSnackbar(true);
            setStatusSnackbar('success');
            setMessageSnackbar(translated_messages.modal_contact_message_success);
        })
        .catch(err => {
            setIsLoading(false);
            setOpenSnackbar(true);
            setStatusSnackbar('error');
            setMessageSnackbar(translated_messages.modal_contact_message_error);
        })

    }

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [statusSnackbar, setStatusSnackbar] = useState('success');
    const [messageSnackbar, setMessageSnackbar] = useState('');

    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <div className={classes.root}>

            <AppBar position="fixed" className={classes.appBar} id="menu">
                <Container>
                    <Toolbar style={{padding: '0px'}}>
                        <Link to="/">
                            <img src={logo} className={classes.img} />
                        </Link>
                        <Box className={classes.links}>
                            &nbsp;
                            {links.map((link, index) => (
                                <Typography
                                    onClick={() => {
                                        window.location.href = link?.link;
                                    }} 
                                    key={index} 
                                    variant="body2" 
                                    className={classes.link}
                                >
                                    {link?.text}
                                </Typography>
                            ))}
                        </Box>
                        <MenuRounded onClick={() => {setOpenDrawer(true)}} className={classes.iconMenu} />
                        <Typography variant="h6" className={classes.title} />
                        {/* <Hidden smUp>
                            <Tooltip title={translated_messages.page_home_button_contact}>
                                <IconButton onClick={() => {setOpenModal(true)}}>
                                    <MailOutlineRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        </Hidden>
                        <Hidden smDown>
                            <Button onClick={() => {setOpenModal(true)}} className={classes.button} size="large">
                                <MailOutlineRoundedIcon /> &nbsp; {translated_messages.page_home_button_contact}
                            </Button>
                        </Hidden> */}
                        <Link to="/login">
                            <Tooltip title="Login">
                                <IconButton>
                                    <AccountCircleOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <CountrySelect refresh sx={{ marginLeft: '20px' }} />
                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer
                anchor="top"
                open={openDrawer}
            >
                <Box style={{ height: '100vh', padding: '20px' }}>
                    <CloseOutlined onClick={() => {setOpenDrawer(false)}} style={{ color: '#000', float: 'right' }} />
                    {links.map((link, index) => (
                        <>
                            {index > 0 && (<br />)}
                            <Typography
                                onClick={() => {
                                    window.location.href = link?.link;
                                }} 
                                key={index} 
                                variant="h5" 
                            >
                                {link?.text}
                            </Typography>
                        </>
                    ))}
                </Box>
            </Drawer>

            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>

                        <CloseOutlined style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 20 }} onClick={() => {setOpenModal(false)}} />

                        <Typography variant="h5">{translated_messages.modal_contact_title}</Typography>
                        <Typography variant="h6" color="textSecondary">{translated_messages.modal_contact_subtitle}</Typography>

                        <CssTextField style={{ marginTop: '10px' }} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight', borderRadius: '8px' } }} onChange={(e) => setName(e.target.value)} value={name} id="outlined-basic" label={translated_messages.modal_contact_field_name} variant="outlined" fullWidth />

                        <CssTextField style={{ marginTop: '10px' }} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight', borderRadius: '8px' } }} onChange={(e) => setEmail(e.target.value)} value={email} id="outlined-basic" label={translated_messages.modal_contact_field_email} variant="outlined" fullWidth />

                        <InputMask maskChar="" mask="(99) 99999-9999" onChange={(e) => setContact(e.target.value)} value={contact}>
                            {(inputProps) => <CssTextField type="tel" style={{ marginTop: '10px' }} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight', borderRadius: '8px' } }} id="outlined-basic" label={translated_messages.modal_contact_field_contact} variant="outlined" fullWidth />}
                        </InputMask>

                        <CssTextField style={{ marginTop: '10px' }} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight', borderRadius: '8px' } }} onChange={(e) => setDescription(e.target.value)} value={description} id="outlined-basic" label={translated_messages.modal_contact_field_message} placeholder={translated_messages.modal_contact_field_message_placeholder} variant="outlined" multiline rows={10} fullWidth />

                        <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop: '10px'}}>
                            <Button onClick={handleSendContact} className={classes.buttonContact} disabled={!name || !contact || !email || !description ? true : false} variant="contained" color="primary" size="large">
                                {isLoading ?
                                    <CircularProgress size={20} color="#FFF" />
                                :
                                    translated_messages.modal_contact_button
                                }
                            </Button>
                        </div>

                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={statusSnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>
      
        </div>
    );
}

export default Menu;